// import { isBefore } from 'date-fns';
import { getJwtPayload } from '@motionelements/core/src/services/jwt.service';

const isProduction = process.env.VUE_APP_ENV === 'production';
const isPrerender = process.env.VUE_APP_PRERENDER === 'true';

// eslint-disable-next-line
const { internal: isInternal, sub: memberId } = getJwtPayload();

/**
 * Check if the member ID is in the specified percentage group for phased rollout.
 * @param {number} percentage - Percentage traffic (e.g., 10, 20, 30...).
 * @returns {boolean}
 */
/* DO NOT DELETE
function isPhasedRolloutPercentage(percentage) {
    if (!memberId || typeof memberId !== 'string') return false; // Ensure memberId is valid.
    const lastDigit = parseInt(memberId.slice(-1), 10); // Get the last digit.

    // Map percentages to digit ranges:
    switch (percentage) {
        case 10: return lastDigit === 0; // 10% - last digit is 0
        case 20: return lastDigit <= 1; // 20% - last digit is 0 or 1
        case 30: return lastDigit <= 2; // 30% - last digit is 0, 1, or 2
        case 40: return lastDigit <= 3; // 40% - last digit is 0, 1, 2, or 3
        case 50: return lastDigit <= 4; // 50% - last digit is 0, 1, 2, 3, or 4
        case 100: return true; // 100% - all users targeted
        default: return false;
    }
}
 */

export default {
    methods: {
        isFeatureEnabled(feature) {
            switch (feature) {
                case 'library_download':
                case 'disable_paypal_for_twd':
                case 'contributor_new_media_types':
                case 'monthly_subscription':
                case 'midjourney_prompt':
                case 'buyer_new_media_types':
                case 'buyer_midjourney_prompt':
                case 'image_prompt_channel':
                case 'image_prompt_contributor':
                case 'studio_ai_style_transfer_custom':
                case 'studio_ai':
                case 'flag_product':
                case 'delete_contributor_account_2024':
                case 'image_to_video_ai_tool':
                case 'video_upscaler_ai_tool':
                case 'wise':
                case 'search_infinite_scroll':
                case 'image_face_swap_ai_tool':
                case 'ai_contributor_account':
                case 'limited_product_upload':
                case 'music_duration_adjuster_ai_tool':
                case 'music_channel_curations':
                case 'turnstile_captcha':
                    return true;
                case 'send_for_review':
                    return !isProduction || isInternal;

                // case 'search_infinite_scroll':
                //     return !isProduction || isInternal || isPhasedRolloutPercentage(50);

                case 'media_player':
                case 'music_new_categories':
                case 'regenerate_previews':
                case 'sort_popular_local':
                case 'new_paypal':
                case 'search_result_scores':
                case 'payment_success_2024':
                case 'ai_powered_search':
                case 'search_devtools':
                case 'library_ai_tools':
                case 'canva_banner':
                    return !isProduction;
                case 'cancelling_subscription_feedback':
                case 'audio_stems':
                case 'artist_profile_picture_edit':
                case 'artist_element_counts': // MP-2095
                case 'contributor_report_views':
                case 'contributor_report_favorites':
                case 'meilisearch':
                case 'exclude_ai_generated':
                case 'library_deleted_items':
                case 'transitioned_products':
                case 'login_signup_redesign_2023':
                case 'hide_image_similar_model_search_results':
                    return true;
                case 'tw_vat_invoice':
                case 'upload_vector_preview':
                case 'auth-modals':
                case 'set_default_payment_method':
                case 'facebook_share':
                case 'google_login_signup':
                case 'library_enhance_button':
                    return false;
                case 'recaptcha':
                    return !isPrerender && (isProduction || ['staging'].includes(process.env.VUE_APP_ENV));
                // case 'contributor_report_views':
                // case 'contributor_report_favorites':
                //     return !isProduction || isInternal; // es issue 2022-08-12
                    // return !isProduction || this.$i18n.locale === 'zh-hant' || isInternal;
                case 'quote':
                case 'credits_purchase':
                case 'payout_deactivated_contributor_message':
                default:
                    return false;
            }
        },
    },
};
